@import "index.css";

body::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
}

/* background of the scrollbar except button or resizer */
body::-webkit-scrollbar-track {
    background-color: #fff;
}

/* scrollbar itself */
body::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
body::-webkit-scrollbar-button {
    display: none;
}

.App {
    background-image: linear-gradient(#2684D9, #0B4C87);
    -webkit-touch-callout: none;
}

.ant-form-item-explain-error {
    background-color: white !important;
    margin: .3em 0;
    padding: .5em;
    border-radius: .1em;
}

.prefix-icon {
    color: white;
}

.ant-form-item-has-error .ant-input {
    color: black;
}

.ant-form-item-has-error .prefix-icon {
    color: red;
}

.ant-show-help-leave {

}

/*loading message for pdf viewer*/
.react-pdf__message {
    display: none;
}

div {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

* {
    font-family: 'Inter', sans-serif;
}
